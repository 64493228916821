export const SAFE_PROTOCOLS = ["https:"];
export const SAFE_HOSTNAMES = [".acronis.com", ".acronis.work", ".localhost"];

export const HEADER_REQUEST_ID = "x-request-id";

export const AUTH_FACTOR_ONE_TIME_PASSWORD = "One-time password";
export const AUTH_FACTOR_PASSKEY = "Passkey";
export const AUTH_FACTOR_PASSWORD = "Password";

export const SEVERITY_INFO = "info";
export const SEVERITY_WARNING = "warning";
export const SEVERITY_CRITICAL = "critical";

export const AUTHENTICATOR_ATTACHMENT_PLATFORM = "platform";
export const AUTHENTICATOR_ATTACHMENT_CROSS_PLATFORM = "cross-platform";

const PASSWORD = "password";
const ONBOARDING = "onboarding"
const SIGN_IN = "signin";
const ENROLL = "enroll";

export const flows = {
    PASSWORD,
    ONBOARDING,
    SIGN_IN,
    ENROLL,
}

export const SAME_SITE_LAX = "Lax";
