
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import Alert from "@/components/Alert.vue";
import QrcodeVue from "qrcode.vue";
import Nickname from "@/views/support/Nickname.vue";

import api from "@/api";
import * as consts from "@/consts";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    Main,
    Alert,
    Dialog,
    LinkCard,
    MultiCard,
    CardItem,
    QrcodeVue,
    Nickname,
  },
  data: () => ({
    code: "",
    codeExpiresAt: "",
    codeVisible: false,
    passkeys: new types.PagedResponse(),
    loading: true,
    create: {
      visible: false,
      loading: false,
      mobile: {
        visible: false,
      },
    },
    update: {
      visible: false,
      loading: false,
      passkey: new types.Passkey(),
      form: {
        displayName: "",
        error: "",
        rules: {
          displayName: [{ required: true }],
        },
      },
    },
    remove: {
      visible: false,
      loading: false,
      passkey: new types.Passkey(),
      form: {
        error: "",
        rules: {},
      },
      reasonOptions: [
        {
          label: "Unspecified",
          value: "Unspecified",
        },
        {
          label: "Device lost or stolen",
          value: "KeyCompromise",
        },
      ],
    },
  }),
  computed: {
    origin(): string {
      return document.location.origin;
    },
    magicLink(): string {
      return `/login?accountID=${this.$store.state.account.id}&flow=${consts.flows.ENROLL}#${this.code}`;
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get("/api/v1/passkeys")
        .then((response) => {
          this.passkeys = new types.PagedResponse(response.data);
          const thisIndex = this.passkeys.items.findIndex(
            (k: types.Passkey) => k.id === this.$store.state.session.passkeyID
          );
          this.passkeys.items.push(...this.passkeys.items.splice(0, thisIndex));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createPasskey(callback: Function) {
      this.create.loading = true;
      api
        .post("/api/v1/login/otp", {})
        .then((response) => {
          this.code = response.data.otp;
          this.codeExpiresAt = response.data.expiresAt;
          callback();
        })
        .finally(() => {
          this.create.loading = false;
        });
    },
    removePasskey() {
      this.remove.loading = true;
      api
        .delete(`/api/v1/passkeys/${this.remove.passkey.id}`)
        .then(() => {
          this.remove.visible = false;
          this.load();
        })
        .catch((error) => {
          this.remove.form.error = util.error(error);
        })
        .finally(() => {
          this.remove.loading = false;
        });
    },
    updatePasskey() {
      this.update.loading = true;
      api
        .put(`/api/v1/passkeys/${this.update.passkey.id}`, {
          displayName: this.update.form.displayName,
        })
        .then(() => {
          this.update.visible = false;
          this.load();
        })
        .catch((error) => {
          this.update.form.error = util.error(error);
        })
        .finally(() => {
          this.update.loading = false;
        });
    },
    createPasskeyNext() {
      api.post("/api/v1/logout", {}).then(() => {
        const magicLink = this.magicLink;
        this.$store.dispatch("logout", false);
        this.$router.push(magicLink);
      });
    },
  },
  mounted() {
    this.load();
  },
});
