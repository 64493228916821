
import Vue from "vue";
import Card from "@/components/layouts/Card.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Card,
  },
  data: () => ({
    metrics: new types.CourseMetrics(),
    loading: true,
  }),
  props: {
    course: {
      type: types.Course,
      required: true,
    },
  },
  computed: {
    options(): any {
      return {
        chart: {
          type: "area",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: this.metrics.series?.map((e) => {
            return e.timestamp;
          }),
          type: "datetime",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          max: 100,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        colors: ["#2668C5"],
        stroke: {
          lineCap: "round",
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
      };
    },
    series(): any {
      return [
        {
          name: "%",
          data: this.metrics.series?.map((e) => {
            return e.progress;
          }),
        },
      ];
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/metrics/courses/${this.course.id}`)
        .then((response) => {
          this.metrics = new types.CourseMetrics(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
