
import Vue from "vue";
import Alert from "@/components/Alert.vue";
import Anchor from "@/components/Anchor.vue";
import Codebox from "@/components/Codebox.vue";
import Collapse from "@/components/Collapse.vue";

export default Vue.extend({
  components: {
    Alert,
    Anchor,
    Codebox,
    Collapse,
  },
  data: () => ({}),
  computed: {},
  methods: {},
});
