
import Vue from "vue";
import Dialog from "@/components/layouts/Dialog.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Dialog,
    MultiCard,
    CardItem,
  },
  data: () => ({
    key: null as types.SSHKey | null,
    loading: true,
    revoke: {
      visible: false,
      loading: false,
    },
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  computed: {},
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/accounts/${this.account.id}/sshkey`)
        .then((response) => {
          this.key = new types.SSHKey(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    revokeKey() {
      this.revoke.loading = true;
      api
        .delete(`/api/v1/accounts/${this.account.id}/sshkey`)
        .then(() => {
          this.load();
          this.$emit("update");
          this.revoke.visible = false;
        })
        .finally(() => {
          this.revoke.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
