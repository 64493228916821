
import Vue from "vue";
import Alert from "@/components/Alert.vue";
import Anchor from "@/components/Anchor.vue";
import Collapse from "@/components/Collapse.vue";
import Matrix from "@/components/Matrix.vue";
import Nickname from "@/views/support/Nickname.vue";
import SetupScreenLock from "@/views/support/SetupScreenLock.vue";
import SetupTouchFaceID from "@/views/support/SetupTouchFaceID.vue";
import SetupTouchID from "@/views/support/SetupTouchID.vue";
import SetupWindowsHello from "@/views/support/SetupWindowsHello.vue";

export default Vue.extend({
  components: {
    Alert,
    Anchor,
    Collapse,
    Matrix,
    Nickname,
    SetupScreenLock,
    SetupTouchFaceID,
    SetupTouchID,
    SetupWindowsHello,
  },
  data: () => ({
    compatibility: {
      columns: [
        {
          prop: "browser",
          label: "",
        },
        {
          prop: "windows",
          label: "Windows 10+",
        },
        {
          prop: "macos",
          label: "macOS 10.15+",
        },
        {
          prop: "linux",
          label: "Desktop Linux",
        },
        {
          prop: "android",
          label: "Android 7+",
        },
        {
          prop: "ios",
          label: "iOS 14.5+",
        },
      ],
      builtin: [
        {
          browser: "Chrome",
          windows: true,
          macos: true,
          linux: false,
          android: true,
          ios: true,
        },
        {
          browser: "Firefox",
          windows: true,
          macos: true,
          linux: false,
          android: true,
          ios: true,
        },
        {
          browser: "Safari",
          windows: undefined,
          macos: true,
          linux: undefined,
          android: undefined,
          ios: true,
        },
      ],
      external: [
        {
          browser: "Chrome",
          windows: true,
          macos: true,
          linux: true,
          android: true,
          ios: true,
        },
        {
          browser: "Firefox",
          windows: true,
          macos: true,
          linux: true,
          android: true,
          ios: true,
        },
        {
          browser: "Safari",
          windows: undefined,
          macos: true,
          linux: undefined,
          android: undefined,
          ios: true,
        },
      ],
    },
  }),
  computed: {},
  methods: {},
});
