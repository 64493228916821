
import Vue from "vue";
import Alert from "@/components/Alert.vue";
import LoginApp from "@/components/layouts/LoginApp.vue";
import Nickname from "@/views/support/Nickname.vue";
import SetupScreenLock from "@/views/support/SetupScreenLock.vue";
import SetupTouchID from "@/views/support/SetupTouchID.vue";
import SetupTouchFaceID from "@/views/support/SetupTouchFaceID.vue";
import SetupWindowsHello from "@/views/support/SetupWindowsHello.vue";

import api from "@/api";
import * as consts from "@/consts";
import util from "@/util";

export default Vue.extend({
  components: {
    Alert,
    LoginApp,
    Nickname,
    SetupScreenLock,
    SetupTouchID,
    SetupTouchFaceID,
    SetupWindowsHello,
  },
  data: () => ({
    form: {
      nickname: "",
      error: "",
      rules: {},
    },
    loading: false,
    disabled: false,
    platformAuthenticatorIsAvailable: true,
  }),
  computed: {
    nickname(): string {
      return [
        util.userAgent.os.name,
        util.userAgent.browser.name,
        util.toDatetime(new Date().toString()),
      ].join(" / ");
    },
    authenticatorAttachment(): string {
      return this.platformAuthenticatorIsAvailable
        ? consts.AUTHENTICATOR_ATTACHMENT_PLATFORM
        : consts.AUTHENTICATOR_ATTACHMENT_CROSS_PLATFORM;
    },
    authenticatorAttachmentCrossPlatform(): string {
      return consts.AUTHENTICATOR_ATTACHMENT_CROSS_PLATFORM;
    },
  },
  methods: {
    register(attachment?: string) {
      this.form.error = "";
      this.loading = true;
      this.form.nickname = this.form.nickname.trim() || this.nickname;
      api
        .post("/api/v1/passkeys", {})
        .then((response) => {
          const pk = response.data.publicKey;
          pk.challenge = util.b64d(pk.challenge);
          pk.user.id = util.b64d(pk.user.id);
          if (pk.excludeCredentials) {
            pk.excludeCredentials.forEach(function (cred: any) {
              cred.id = util.b64d(cred.id);
            });
          }
          pk.authenticatorSelection.authenticatorAttachment =
            attachment || this.authenticatorAttachment;
          return navigator.credentials.create({ publicKey: pk });
        })
        .then((cred: any) => {
          const opts = util.query({
            displayName: this.form.nickname,
          });
          return api.post(`/api/v1/passkeys/verify?${opts}`, {
            id: cred.id,
            rawId: util.b64(cred.rawId),
            response: {
              attestationObject: util.b64(cred.response.attestationObject),
              clientDataJSON: util.b64(cred.response.clientDataJSON),
            },
            type: cred.type,
          });
        })
        .then(() => {
          this.$store.state.message = {
            title: "Passkey created",
            message: "You can now use it to verify it's really you signing in.",
            type: "success",
          };
          this.$router.push({
            path: "/login/checkup",
            query: this.$route.query,
            hash: this.$route.hash,
          });
        })
        .catch((error) => {
          this.form.error = util.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back() {
      const query = this.$route.query;
      query.flow = consts.flows.SIGN_IN;
      this.$router.push({
        path: "/login",
        query: query,
        hash: this.$route.hash,
      });
    },
  },
  mounted() {
    if (!util.browserSupportsWebAuthn()) {
      this.form.error = "This browser doesn't support passkeys";
      this.disabled = true;
    } else {
      window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then(
        (is: boolean) => {
          this.platformAuthenticatorIsAvailable = is;
        }
      );
    }
  },
});
