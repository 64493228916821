
import Vue from "vue";
import CheckupItem from "@/views/checkup/CheckupItem.vue";

import * as types from "@/types";

export default Vue.extend({
  components: {
    CheckupItem,
  },
  data: () => ({}),
  props: {
    data: {
      type: types.ExternalAccountsCheckup,
      default: () => new types.ExternalAccountsCheckup(),
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  methods: {},
});
