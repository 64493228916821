
import Vue from "vue";
import AsideMain from "@/components/layouts/AsideMain.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    AsideMain,
    LinkCard,
  },
  data: () => ({
    inventory: {
      data: new types.Counter(),
      loading: true,
    },
    licenses: {
      data: new types.Counter(),
      loading: true,
    },
    managedAccounts: {
      data: new types.PagedResponse(),
      loading: true,
    },
  }),
  computed: {
    userAccountsCount(): number {
      return (
        this.managedAccounts.data.items?.length - this.serviceAccountsCount
      );
    },
    serviceAccountsCount(): number {
      return (
        this.managedAccounts.data.items?.filter(
          (account) => account.props.isServiceAccount
        ).length || 0
      );
    },
  },
  methods: {
    getDevicesCount() {
      this.inventory.loading = true;
      api
        .get("/api/v1/inventory/count")
        .then((response) => {
          this.inventory.data = new types.Counter(response.data);
        })
        .finally(() => {
          this.inventory.loading = false;
        });
    },
    getLicenseKeysCount() {
      this.licenses.loading = true;
      api
        .get("/api/v1/licenses/count")
        .then((response) => {
          this.licenses.data = new types.Counter(response.data);
        })
        .finally(() => {
          this.licenses.loading = false;
        });
    },
    getManagedAccountsCount() {
      this.managedAccounts.loading = true;
      api
        .get("/api/v1/managed-accounts")
        .then((response) => {
          this.managedAccounts.data = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.managedAccounts.loading = false;
        });
    },
    load() {
      this.getDevicesCount();
      this.getLicenseKeysCount();
      this.getManagedAccountsCount();
    },
  },
  mounted() {
    this.load();
  },
});
