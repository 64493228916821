
import Vue from "vue";

import api from "@/api";
import * as types from "@/types";
import { requireAccount, getRole } from "@/router";
import util from "@/util";

export default Vue.extend({
  components: {},
  data: () => ({}),
  computed: {},
  methods: {
    loadAccount() {
      this.$store.state.waiting = true;
      api
        .get("/api/v1/me")
        .then((response) => {
          this.$store.dispatch("update", new types.Account(response.data));
          const hasRole = getRole(this.$route)();
          if (!hasRole) {
            this.$store.state.message = {
              title: "Access denied",
              message: "You don't have required permissions.",
              type: "error",
            };
            this.$router.push("/");
          }
        })
        .finally(() => {
          this.$store.state.waiting = false;
        });
    },
    resetMessage() {
      this.$store.state.message = null;
    },
  },
  created() {
    if (requireAccount(this.$route)) {
      this.loadAccount();
    }
  },
  mounted() {
    window.addEventListener("load", () => {
      util.scroll(this.$route.hash);
    });
  },
  updated() {
    if (!this.$store.state.account && requireAccount(this.$route)) {
      this.loadAccount();
    }
  },
});
