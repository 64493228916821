
import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({
    text: "",
    error: "",
    invalid: false,
  }),
  props: {
    value: {
      type: Array,
    },
    prop: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
  },
  computed: {
    options(): string[] {
      return this.value ? (this.value as string[]) : [];
    },
  },
  methods: {
    add() {
      const text = this.text.trim();
      if (text) {
        this.$emit("input", this.options.concat([text]).sort());
      }
      this.text = "";
    },
    remove(value: string) {
      this.$emit(
        "input",
        this.options.filter((item) => {
          return item !== value;
        })
      );
    },
  },
  watch: {
    text(newValue: string) {
      const options = this.options.map((item: string) => {
        return item.toLowerCase().trim();
      });
      if (options.includes(newValue.toLowerCase().trim())) {
        this.error = "duplicate entry";
        this.invalid = true;
      } else {
        this.error = "";
        this.invalid = false;
      }
    },
  },
});
