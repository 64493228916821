
import Vue from "vue";

import * as types from "@/types";

export default Vue.extend({
  components: {},
  data: () => ({
    group: "",
    role: "",
    error: "",
    invalid: false,
  }),
  props: {
    value: {
      type: Array,
    },
    groups: {
      type: Array,
    },
    readonly: {
      type: Boolean,
    },
  },
  computed: {
    options(): types.ApplicationRole[] {
      return (this.value as types.ApplicationRole[]) || [];
    },
  },
  methods: {
    add() {
      const role = this.role.trim();
      if (role) {
        this.$emit(
          "input",
          this.options.concat(
            new types.ApplicationRole({ displayName: role, group: this.group })
          )
        );
      }
      this.group = "";
      this.role = "";
    },
    remove(value: types.ApplicationRole) {
      this.$emit(
        "input",
        this.options.filter((item) => {
          return item !== value;
        })
      );
    },
  },
  watch: {
    role(newValue: string) {
      const options = this.options.map((role: types.ApplicationRole) => {
        return role.displayName.toLowerCase().trim();
      });
      if (options.includes(newValue.toLowerCase().trim())) {
        this.error = "duplicate entry";
        this.invalid = true;
      } else {
        this.error = "";
        this.invalid = false;
      }
    },
  },
});
