
import Vue from "vue";
import Card from "@/components/layouts/Card.vue";
import Link from "@/components/Link.vue";

export default Vue.extend({
  components: {
    Card,
    Link,
  },
  data: () => ({}),
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    icon: {
      type: String,
    },
    to: {
      type: String,
    },
    clickable: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {},
  methods: {},
});
