import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

import * as consts from "@/consts";
import store from "@/store";

import AdminRouter from "@/views/admin/Router.vue";
import AdminAccountSearch from "@/views/admin/accounts/Search.vue";
import AdminAccount from "@/views/admin/accounts/Account.vue";
import AdminApplicationSearch from "@/views/admin/applications/Search.vue";
import AdminApplication from "@/views/admin/applications/Application.vue";
import AdminCourseSearch from "@/views/admin/courses/Search.vue";
import AdminCourse from "@/views/admin/courses/Course.vue";
import AdminLicenses from "@/views/admin/Licenses.vue";

import ApplicationsSearch from "@/views/applications/Search.vue";

import Checkup from "@/views/checkup/Checkup.vue";

import About from "@/views/personal/About.vue";
import Inventory from "@/views/personal/Inventory.vue";
import Licenses from "@/views/personal/Licenses.vue";
import PersonalInfoRouter from "@/views/personal/Router.vue";

import ManagedAccountRouter from "@/views/personal/managed-accounts/ManagedAccount.vue";
import ManagedAccountSearch from "@/views/personal/managed-accounts/Search.vue";

import AccessRequests from "@/views/security/AccessRequests.vue";
import AccessToken from "@/views/security/AccessToken.vue";
import Devices from "@/views/security/Devices.vue";
import Events from "@/views/security/Events.vue";
import OneTimeTokenCreate from "@/views/security/OneTimeTokenCreate.vue";
import Passkeys from "@/views/security/Passkeys.vue";
import PasskeysCreate from "@/views/security/PasskeysCreate.vue";
import Password from "@/views/security/Password.vue";
import PasswordUpdate from "@/views/security/PasswordUpdate.vue";
import SecurityRouter from "@/views/security/Router.vue";
import SSHKey from "@/views/security/SSHKey.vue";

import Login from "@/views/Login.vue";
import LoginOneTimePassword from "@/views/LoginOneTimePassword.vue";
import LoginOneTimeToken from "@/views/LoginOneTimeToken.vue";
import LoginPasskeys from "@/views/LoginPasskeys.vue";
import LoginPassword from "@/views/LoginPassword.vue";
import Logout from "@/views/Logout.vue";

import Support from "@/views/support/Support.vue";

import Debug from "@/views/Debug.vue";
import ErrorAccessDenied from "@/views/ErrorAccessDenied.vue";
import ErrorNotFound from "@/views/ErrorNotFound.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  //
  // Sign in
  //
  {
    path: "/login",
    component: Login,
    meta: {
      auth: store.getters.hasNothing,
    },
    props: route => ({
      accountID: route.query.accountID,
      flow: route.query.flow,
    }),
  },
  {
    path: "/login/ott",
    component: LoginOneTimeToken,
    meta: {
      auth: store.getters.hasNothing,
    },
    props: route => ({
      flow: route.query.flow,
      from: route.query.from,
      sessionID: route.query.sessionID,
      oneTimeToken: route.hash.replace("#", ""),
    }),
  },
  {
    path: "/login/otp",
    component: LoginOneTimePassword,
    meta: {
      auth: store.getters.hasSession,
    },
    props: route => ({
      flow: route.query.flow,
      oneTimePassword: route.hash.replace("#", ""),
    }),
  },
  {
    path: "/login/passkeys",
    component: LoginPasskeys,
    meta: {
      auth: store.getters.hasSession,
    },
    props: route => ({
      flow: route.query.flow,
    }),
  },
  {
    path: "/login/password",
    component: LoginPassword,
    meta: {
      auth: store.getters.hasOneFactor,
    },
    props: route => ({
      flow: route.query.flow,
      from: route.query.from,
      next: route.query.next,
    }),
  },
  {
    path: "/login/checkup",
    component: Checkup,
    meta: {
      auth: store.getters.hasPasskeyAndPassword,
    },
    props: route => ({
      flow: route.query.flow,
      from: route.query.from,
      redirect: route.query.redirect,
    }),
  },
  {
    path: "/logout",
    component: Logout,
    meta: {
      auth: store.getters.hasNothing,
    },
    props: route => ({
      force: route.query.force === "1",
      from: route.query.from,
    }),
  },
  // 
  // Applications
  // 
  {
    path: "/",
    redirect: "/applications",
  },
  {
    path: "/services",
    redirect: "/applications",
  },
  {
    path: "/applications",
    component: ApplicationsSearch,
  },
  //
  // Sign-in and security
  //
  {
    path: "/security",
    component: SecurityRouter,
  },
  {
    path: "/password",
    component: Password,
    meta: {
      back: "/security",
    },
  },
  {
    path: "/password/update",
    component: PasswordUpdate,
    meta: {
      auth: store.getters.hasSession,
      back: "/password",
      flow: consts.flows.PASSWORD,
    },
    props: route => ({
      from: route.query.from,
      expired: route.query.expired === "1",
      required: route.query.required === "1",
    }),
  },
  {
    path: "/passkeys",
    component: Passkeys,
    meta: {
      back: "/security",
    },
  },
  {
    path: "/passkeys/create",
    component: PasskeysCreate,
    meta: {
      auth: store.getters.hasOneTimePasswordAndPassword,
      flow: consts.flows.ENROLL,
    },
  },
  {
    path: "/sshkey",
    component: SSHKey,
    meta: {
      back: "/security",
    },
  },
  {
    path: "/token",
    component: AccessToken,
    meta: {
      back: "/security",
    },
  },
  {
    path: "/access-requests",
    component: AccessRequests,
    meta: {
      back: "/security",
    },
  },
  {
    path: "/devices",
    component: Devices,
    meta: {
      back: "/security",
    },
  },
  {
    path: "/events",
    component: Events,
    meta: {
      back: "/security",
    },
  },
  {
    path: "/ott/create",
    component: OneTimeTokenCreate,
  },
  // 
  // Personal information
  // 
  {
    path: "/personal",
    component: PersonalInfoRouter,
  },
  {
    path: "/about",
    component: About,
    meta: {
      back: "/personal",
    },
  },
  {
    path: "/inventory",
    component: Inventory,
    meta: {
      back: "/personal",
    },
  },
  {
    path: "/managed-accounts",
    component: ManagedAccountSearch,
    meta: {
      back: "/personal",
    },
  },
  {
    path: "/managed-accounts/:id",
    component: ManagedAccountRouter,
    meta: {
      back: "/managed-accounts",
    },
  },
  {
    path: "/external-accounts",
    redirect: "/managed-accounts",
  },
  {
    path: "/external-accounts/:id",
    redirect: "/managed-accounts/:id",
  },
  {
    path: "/service-accounts",
    redirect: "/managed-accounts",
  },
  {
    path: "/service-accounts/:id",
    redirect: "/managed-accounts/:id",
  },
  {
    path: "/licenses",
    component: Licenses,
    meta: {
      back: "/personal",
    },
  },
  //
  // Administration
  //
  {
    path: "/admin",
    component: AdminRouter,
    meta: {
      role: store.getters.hasAnyAdminRole,
    }
  },
  {
    path: "/admin/accounts",
    component: AdminAccountSearch,
    meta: {
      back: "/admin",
      role: store.getters.hasAdminRole,
    }
  },
  {
    path: "/admin/accounts/:id",
    component: AdminAccount,
    meta: {
      back: "/admin/accounts",
      role: store.getters.hasAdminRole,
    }
  },
  {
    path: "/admin/applications",
    component: AdminApplicationSearch,
    meta: {
      back: "/admin",
      role: store.getters.hasAdminOrApplicationAdminRole,
    }
  },
  {
    path: "/admin/applications/:id",
    component: AdminApplication,
    meta: {
      back: "/admin/applications",
      role: store.getters.hasAdminOrApplicationAdminRole,
    }
  },
  {
    path: "/admin/courses",
    component: AdminCourseSearch,
    meta: {
      back: "/admin",
      role: store.getters.hasAdminOrTrainingAdminRole,
    }
  },
  {
    path: "/admin/courses/:id",
    component: AdminCourse,
    meta: {
      back: "/admin/courses",
      role: store.getters.hasAdminOrTrainingAdminRole,
    }
  },
  {
    path: "/admin/licenses",
    component: AdminLicenses,
    meta: {
      back: "/admin",
      role: store.getters.hasAdminOrLicensingAdminRole,
    }
  },
  // 
  // Public debug and help
  // 
  {
    path: "/debug",
    component: Debug,
    meta: {
      auth: store.getters.hasNothing,
    },
  },
  {
    path: "/support",
    component: Support,
    meta: {
      auth: store.getters.hasNothing,
    },
  },
  {
    path: "/support/:id",
    component: Support,
    meta: {
      auth: store.getters.hasNothing,
    },
  },
  // 
  // Known errors
  // 
  {
    path: "/403",
    component: ErrorAccessDenied,
    props: route => ({
      applicationID: route.query.applicationID,
    }),
  },
  {
    path: "**",
    component: ErrorNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (to.hash) {
        resolve({ selector: to.hash, offset: { x: 0, y: 96 } })
      } else {
        resolve({ x: 0, y: 0 });
      }
    });
  }
});

export function getAuth(route: Route): Function {
  return route.meta?.auth !== undefined ? route.meta?.auth : store.getters.hasPasskeyAndPassword;;
}

export function getFlow(route: Route): string {
  return route.meta?.flow || consts.flows.SIGN_IN;
}

export function getRole(route: Route): Function {
  return route.meta?.role !== undefined ? route.meta?.role : store.getters.hasUserRole;
}

export function isLogin(route: Route): boolean {
  return route.path.startsWith("/login");
}

export function requireAccount(route: Route): boolean {
  return getAuth(route) === store.getters.hasPasskeyAndPassword;
}

router.beforeEach((to, from, next) => {
  store.dispatch("reload");
  const hasAuth = getAuth(to)();
  const onLogin = isLogin(to);
  if (onLogin && !hasAuth) {
    return next({
      path: "/login",
      query: to.query,
      hash: to.hash,
    });
  }
  if (!onLogin && !hasAuth) {
    return next({
      path: "/login",
      query: {
        flow: getFlow(to),
        from: to.fullPath,
      },
    });
  }
  next();
});

export default router;
