
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Header from "@/components/Header.vue";
import Menu from "@/components/Menu.vue";

export default Vue.extend({
  components: {
    Main,
    Header,
    Menu,
  },
  data: () => ({}),
  props: {
    loading: {
      type: Boolean,
    },
    reverse: {
      type: Boolean,
    },
  },
  computed: {},
  methods: {},
});
