
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import Search from "@/components/Search.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    Main,
    CardItem,
    LinkCard,
    MultiCard,
    Search,
  },
  data: () => ({
    accounts: {
      data: new types.PagedResponse(),
      loading: false,
      timeout: 0,
      query: "",
    },
    metrics: {
      data: new types.MetricsAccounts(),
      loading: true,
    },
  }),
  computed: {},
  methods: {
    load() {
      this.metrics.loading = true;
      api
        .get("/api/v1/metrics/accounts")
        .then((response) => {
          this.metrics.data = new types.MetricsAccounts(response.data);
        })
        .finally(() => {
          this.metrics.loading = false;
        });
    },
    findAccounts(query: string) {
      query = query.trim().toLowerCase();
      if (query === "" || query.length < 3) {
        this.accounts.data = new types.PagedResponse();
        return;
      }
      this.accounts.loading = true;
      clearTimeout(this.accounts.timeout);
      this.accounts.timeout = setTimeout(() => {
        const opts = util.query({
          query: query,
          sort: "attrs.displayName",
        });
        api
          .get(`/api/v1/accounts?${opts}`)
          .then((response) => {
            this.accounts.data = new types.PagedResponse(response.data);
          })
          .finally(() => {
            this.accounts.loading = false;
          });
      }, 500);
    },
  },
  mounted() {
    this.load();
  },
});
