
import Vue from "vue";
import Alert from "@/components/Alert.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import Dialog from "@/components/layouts/Dialog.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    Alert,
    MultiCard,
    CardItem,
    Dialog,
  },
  data: () => ({
    extend: {
      visible: false,
      loading: false,
      form: {
        expiresAt: new Date(),
        error: "",
        rules: {
          expiresAt: [{ required: true }],
        },
      },
    },
    terminate: {
      visible: false,
      loading: false,
      form: {
        error: "",
        rules: {
          displayName: [{ required: true }],
        },
      },
    },
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  computed: {
    defaultExpiresAt(): Date {
      const t = new Date();
      t.setMonth(t.getMonth() + 1);
      return t;
    },
  },
  methods: {
    extendExpiration() {
      this.extend.loading = true;
      this.extend.form.expiresAt.setHours(0, 0, 0, 0);
      api
        .put(`/api/v1/external-accounts/${this.account.id}/expiration`, {
          expiresAt: this.extend.form.expiresAt,
        })
        .then((response) => {
          this.account.attrs.accountExpires =
            this.extend.form.expiresAt.toString();
          this.extend.visible = false;
        })
        .catch((error) => {
          this.extend.form.error = util.error(error);
        })
        .finally(() => {
          this.extend.loading = false;
        });
    },
    terminateAccount() {
      this.terminate.loading = true;
      api
        .delete(`/api/v1/external-accounts/${this.account.id}`)
        .then((response) => {
          this.account.attrs.accountExpires = undefined;
          this.terminate.visible = false;
        })
        .catch((error) => {
          this.terminate.form.error = util.error(error);
        })
        .finally(() => {
          this.terminate.loading = false;
        });
    },
    optionsExpiresAt(account: types.Account): any {
      return {
        disabledDate(time: Date) {
          const min = new Date();
          const fromNow = new Date();
          fromNow.setDate(min.getDate() + 120);
          const fromExp = new Date(account.attrs.accountExpires || min);
          fromExp.setDate(fromExp.getDate() + 90);
          const max = fromExp < fromNow ? fromExp : fromNow;
          return time < min || time > max;
        },
      };
    },
  },
});
