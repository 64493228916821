
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";
import Search from "@/components/Search.vue";

import api from "@/api";
import store from "@/store";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Main,
    Dialog,
    LinkCard,
    Search,
  },
  data: () => ({
    query: "",
    applications: new types.PagedResponse(),
    loading: true,
    create: {
      form: {
        displayName: "",
        error: "",
        rules: {
          displayName: [{ required: true }],
        },
      },
      loading: false,
      visible: false,
    },
  }),
  computed: {
    readonly(): boolean {
      return !store.getters.hasApplicationAdminRole();
    },
    filtered(): types.Application[] {
      return this.filterApplications(this.applications.items)?.sort(
        (a: types.Application, b: types.Application) => {
          return a.displayName.localeCompare(b.displayName);
        }
      );
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get("/api/v1/applications")
        .then((response) => {
          this.applications = new types.PagedResponse(response.data);
          this.applications.items?.map((value: any, index: number) => {
            this.applications.items[index] = new types.Application(value);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createApplication() {
      this.create.loading = true;
      api
        .post("/api/v1/applications", {
          displayName: this.create.form.displayName,
        })
        .then((response) => {
          const app = new types.Application(response.data);
          this.$router.push(`/admin/applications/${app.id}`);
        })
        .finally(() => {
          this.create.loading = false;
          this.create.visible = false;
        });
    },
    filterApplications(applications: types.Application[]): types.Application[] {
      const query = this.query.trim().toLowerCase();
      if (query === "") {
        return applications;
      }
      return applications.filter((s: types.Application) => {
        return (
          s.displayName.toLowerCase().includes(query) ||
          s.url.toLowerCase().replace(".acronis.work", "").includes(query)
        );
      });
    },
  },
  mounted() {
    this.load();
  },
});
