
import Vue from "vue";
import TableCard from "@/components/layouts/TableCard.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    TableCard,
  },
  data: () => ({
    accesses: new types.PagedResponse(),
    filtered: new Array<types.AccessRequest>(),
    loading: true,
    columns: [
      {
        prop: "system.id",
        label: "System ID",
      },
      {
        prop: "system.name",
        label: "System",
      },
      {
        prop: "role.id",
        label: "Role ID",
      },
      {
        prop: "role.name",
        label: "Role",
      },
      {
        prop: "approved",
        label: "Status",
        format: (row: any, column: any, approved: boolean) =>
          approved ? "Approved" : "Awaiting approval",
      },
    ],
    alwaysColumns: ["system.name", "role.name", "approved"],
    hiddenColumns: ["system.id", "role.id"],
    actions: [
      {
        label: "Open in SPMP",
        command: "openInSPMP",
        icon: "i-external-link-o--16",
      },
      {
        label: "Copy system ID",
        command: "copySystemID",
        icon: "i-copy-o--16",
      },
      { label: "Copy role ID", command: "copyRoleID", icon: "i-copy-o--16" },
    ],
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/accounts/${this.account.id}/accesses`)
        .then((response) => {
          this.accesses = new types.PagedResponse(response.data);
          this.accesses.items = this.accesses.items || [];
          this.filtered = this.accesses.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search(query: string) {
      this.filtered = this.accesses.items.filter((s: types.AccessRequest) => {
        return (
          s.system.name.toLowerCase().includes(query) ||
          s.role.name.toLowerCase().includes(query)
        );
      });
    },
    openInSPMP(row: any) {
      util.openTab(`https://spmp.acronis.work/systems/${row.system.id}`);
    },
    copySystemID(row: any) {
      util.copy(row.system.id);
    },
    copyRoleID(row: any) {
      util.copy(row.role.id);
    },
  },
  mounted() {
    this.load();
  },
});
