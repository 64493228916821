
import Vue from "vue";
import TableCard from "@/components/layouts/TableCard.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    TableCard,
  },
  data: () => ({
    groups: new types.PagedResponse(),
    filtered: new Array<types.Group>(),
    loading: true,
    columns: [
      {
        prop: "attrs.sAMAccountName",
        label: "sAMAccountName",
      },
      {
        prop: "attrs.managedBy",
        label: "Managed by",
        format: (row: any, column: any, dn: string) => util.cnFromDN(dn),
      },
    ],
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/accounts/${this.account.id}/groups`)
        .then((response) => {
          this.groups = new types.PagedResponse(response.data);
          this.groups.items = this.groups.items || [];
          this.filtered = this.groups.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search(query: string) {
      this.filtered = this.groups.items.filter((g: types.Group) => {
        return g.attrs.sAMAccountName?.toLowerCase().includes(query);
      });
    },
  },
  mounted() {
    this.load();
  },
});
