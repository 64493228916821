
import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({}),
  props: {
    to: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    component(): string {
      if (this.disabled || !this.to) {
        return "span";
      }
      if (this.isExternal) {
        return "a";
      }
      return "router-link";
    },
    isExternal(): boolean {
      if (!this.to) {
        return false;
      }
      return this.to.startsWith("https://");
    },
  },
  methods: {},
});
