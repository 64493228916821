
import Vue from "vue";
import Events from "@/components/Events.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    Events,
  },
  data: () => ({
    events: new types.PagedResponse(),
    loading: true,
  }),
  props: {
    application: {
      type: types.Application,
      required: true,
    },
  },
  computed: {
    loki(): string {
      const opts = util.query({
        schemaVersion: 1,
        panes: JSON.stringify({
          ABC: {
            queries: [
              {
                refId: `${this.application.displayName} audit log`,
                expr: `{service="passport", log_type="journal"} |= \`"module":"audit"\` |= \`"object_application_id":"${this.application.id}"\` | json`,
                queryType: "range",
              },
            ],
            range: { from: "now-5m", to: "now" },
          },
        }),
        orgId: 1,
      });
      return `https://loki.acronis.work/explore?${opts}`;
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/applications/${this.application.id}/events`)
        .then((response) => {
          this.events = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
