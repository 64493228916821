
import Vue from "vue";
import AsideMain from "@/components/layouts/AsideMain.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";

export default Vue.extend({
  components: {
    AsideMain,
    LinkCard,
  },
  data: () => ({}),
  computed: {},
  methods: {},
});
