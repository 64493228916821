
import Vue from "vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import Search from "@/components/Search.vue";

export default Vue.extend({
  components: {
    MultiCard,
    Search,
  },
  data: () => ({
    query: "",
    timeout: 0,
    searching: false,
  }),
  props: {
    title: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    alwaysColumns: {
      type: Array,
    },
    hiddenColumns: {
      type: Array,
    },
    actions: {
      type: Array,
    },
  },
  methods: {
    selectableColumns(column: any) {
      return !this.alwaysColumns.includes(column.prop);
    },
    search() {
      this.searching = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("search", this.query.trim().toLowerCase());
        this.searching = false;
      }, 500);
    },
    action(name: string, row: any) {
      this.$emit(name, row);
    },
  },
});
