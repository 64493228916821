
import Vue from "vue";
import TableCard from "@/components/layouts/TableCard.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    TableCard,
  },
  data: () => ({
    applications: new types.PagedResponse(),
    filtered: new Array<types.Application>(),
    loading: true,
    columns: [
      {
        prop: "id",
        label: "ID",
      },
      {
        prop: "displayName",
        label: "Name",
      },
      {
        prop: "url",
        label: "URL",
      },
    ],
    alwaysColumns: ["displayName"],
    hiddenColumns: ["id"],
    actions: [
      { label: "Copy ID", command: "copy", icon: "i-copy-o--16" },
      { label: "View", command: "view", icon: "i-external-link-o--16" },
    ],
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/accounts/${this.account.id}/applications`)
        .then((response) => {
          this.applications = new types.PagedResponse(response.data);
          this.applications.items = this.applications.items || [];
          this.filtered = this.applications.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search(query: string) {
      this.filtered = this.applications.items.filter((s: types.Application) => {
        return (
          s.displayName.toLowerCase().includes(query) ||
          s.url.toLowerCase().replace(".acronis.work", "").includes(query)
        );
      });
    },
    copyID(row: any) {
      util.copy(row.id);
    },
    view(row: any) {
      util.openTab(`/admin/applications/${row.id}`);
    },
  },
  mounted() {
    this.load();
  },
});
