
import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({}),
  props: {
    value: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    noShortcut: {
      type: Boolean,
    },
    fetchSuggestions: {
      type: Function,
    },
  },
  computed: {
    search(): HTMLElement {
      return this.$refs.search as HTMLElement;
    },
  },
  methods: {
    change(query: string) {
      this.$emit("input", query || "");
    },
    shortcuts(e: any) {
      if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
        this.search.focus();
        e.preventDefault();
      }
      if (e.key === "Escape") {
        this.search.blur();
      }
    },
  },
  mounted() {
    if (!this.noShortcut) {
      window.addEventListener("keydown", this.shortcuts);
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.shortcuts);
  },
});
