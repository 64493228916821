
import Vue from "vue";
import LoginApp from "@/components/layouts/LoginApp.vue";

import api from "@/api";

export default Vue.extend({
  components: {
    LoginApp,
  },
  data: () => ({
    loading: false,
    form: {
      error: "",
      rules: {},
    },
  }),
  props: {
    force: Boolean,
    from: String,
  },
  computed: {},
  methods: {
    logout() {
      this.loading = true;
      api
        .post("/api/v1/logout", {})
        .then(() => {
          this.$store.state.message = {
            title: "Signed out",
            message: "Sign in again to continue.",
            type: "success",
          };
          this.$store.dispatch("logout");
        })
        .finally(() => {
          this.$router.push("/login");
        });
    },
    next() {
      this.$router.push(this.from || this.$route.meta?.back || "/");
    },
  },
  mounted() {
    if (this.force) {
      this.logout();
    }
  },
});
