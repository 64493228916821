
import Vue from "vue";
import Alert from "@/components/Alert.vue";
import Anchor from "@/components/Anchor.vue";

export default Vue.extend({
  components: {
    Alert,
    Anchor,
  },
  data: () => ({}),
  computed: {},
  methods: {},
});
