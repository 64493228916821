
import Vue from "vue";
import Fullscreen from "@/components/layouts/Fullscreen.vue";
import Logo from "@/components/Logo.vue";

export default Vue.extend({
  components: {
    Fullscreen,
    Logo,
  },
  data: () => ({}),
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
  computed: {},
  methods: {},
});
