
import Vue from "vue";
import LoginApp from "@/components/layouts/LoginApp.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    LoginApp,
  },
  data: () => ({
    form: {
      nickname: "",
      error: "",
      rules: {},
    },
    loading: false,
  }),
  computed: {},
  methods: {
    create() {
      this.form.error = "";
      this.loading = true;
      api
        .post("/api/v1/login/ott", {})
        .then((response) => {
          const r = new types.OneTimeToken(response.data);
          document.location.assign(
            `${r.origin}/login/ott?sessionID=${r.sessionID}&from=${document.location.href}#${r.oneTimeToken}`
          );
        })
        .catch((error) => {
          this.form.error = util.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push(this.$route.meta?.back || "/");
    },
  },
});
