
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";
import Search from "@/components/Search.vue";

import api from "@/api";
import * as types from "@/types";
import store from "@/store";

export default Vue.extend({
  components: {
    Main,
    Dialog,
    LinkCard,
    Search,
  },
  data: () => ({
    query: "",
    courses: new types.PagedResponse(),
    loading: true,
    create: {
      form: {
        displayName: "",
        error: "",
        rules: {
          displayName: [{ required: true }],
        },
      },
      loading: false,
      visible: false,
    },
  }),
  computed: {
    readonly(): boolean {
      return !store.getters.hasTrainingAdminRole();
    },
    filtered(): types.Course[] {
      return this.filterCourses(this.courses.items)?.sort(
        (a: types.Course, b: types.Course) => {
          return a.displayName.localeCompare(b.displayName);
        }
      );
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get("/api/v1/courses")
        .then((response) => {
          this.courses = new types.PagedResponse(response.data);
          this.courses.items?.map((value: any, index: number) => {
            this.courses.items[index] = new types.Course(value);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createCourse() {
      this.create.loading = true;
      api
        .post("/api/v1/courses", {
          displayName: this.create.form.displayName,
        })
        .then((response) => {
          const course = new types.Course(response.data);
          this.$router.push(`/admin/courses/${course.id}`);
        })
        .finally(() => {
          this.create.loading = false;
          this.create.visible = false;
        });
    },
    filterCourses(courses: types.Course[]): types.Course[] {
      const query = this.query.trim().toLowerCase();
      if (query === "") {
        return courses;
      }
      return courses.filter((s: types.Course) => {
        return s.displayName.toLowerCase().includes(query);
      });
    },
  },
  mounted() {
    this.load();
  },
});
