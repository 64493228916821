
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";

import api from "@/api";
import * as types from "@/types";
import store from "@/store";
import util from "@/util";

export default Vue.extend({
  components: {
    Main,
    CardItem,
    Dialog,
    MultiCard,
  },
  data: () => ({
    add: {
      license: new types.License(),
      visible: false,
      loading: false,
      form: {
        keys: "",
        rules: {
          keys: [{ required: true }],
        },
        error: "",
      },
    },
    loading: true,
    metrics: new types.PagedResponse(),
  }),
  computed: {
    readonly(): boolean {
      return !store.getters.hasLicensingAdminRole();
    },
  },
  methods: {
    getMetricsForLicenses() {
      this.loading = true;
      api
        .get("/api/v1/metrics/licenses")
        .then((response) => {
          this.metrics = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    load() {
      this.getMetricsForLicenses();
    },
    addLicenseKeys() {
      this.add.loading = true;
      api
        .post(`/api/v1/licenses/${this.add.license.id}/keys`, {
          items: this.add.form.keys.split("\n"),
        })
        .then((response) => {
          this.load();
          this.add.visible = false;
        })
        .catch((error) => {
          this.add.form.error = util.error(error);
        })
        .finally(() => {
          this.add.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
