
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";
import Search from "@/components/Search.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Main,
    LinkCard,
    Search,
  },
  data: () => ({
    accounts: new types.PagedResponse(),
    loading: true,
    query: "",
  }),
  props: {},
  computed: {
    filtered(): types.Account[] {
      return this.filterAccounts(this.accounts.items)?.sort(
        (a: types.Account, b: types.Account) => {
          return a.attrs.displayName.localeCompare(b.attrs.displayName);
        }
      );
    },
    userAccounts(): types.Account[] {
      return this.filtered?.filter(
        (account) => !account.props.isServiceAccount
      );
    },
    serviceAccounts(): types.Account[] {
      const a = this.filtered?.filter(
        (account) => account.props.isServiceAccount
      );
      return a;
    },
  },
  methods: {
    filterAccounts(accounts: types.Account[]): types.Account[] {
      const query = this.query.trim().toLowerCase();
      if (query === "") {
        return accounts;
      }
      return accounts.filter((a: types.Account) => {
        return (
          a.attrs.displayName.toLowerCase().includes(query) ||
          a.attrs.sAMAccountName.toLowerCase().includes(query) ||
          a.attrs.mail.toLowerCase().includes(query)
        );
      });
    },
    load() {
      this.loading = true;
      api
        .get("/api/v1/managed-accounts")
        .then((response) => {
          this.accounts = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
