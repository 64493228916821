var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Card',{class:{
    'link-card': true,
    'link-card-clickable': _vm.clickable,
    'link-card-disabled': _vm.disabled,
  },on:{"click":function($event){if (!_vm.disabled) {
      _vm.$emit('click');
    }}}},[_c('div',{staticClass:"link-card-tag"},[_vm._t("tag")],2),_c('Link',{attrs:{"to":_vm.to,"disabled":_vm.disabled}},[_c('el-container',[_c('el-main',{staticClass:"link-card-main"},[_c('h3',{staticClass:"link-card-title"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2),_c('h4',{staticClass:"link-card-subtitle"},[_vm._t("subtitle",function(){return [_vm._v(" "+_vm._s(_vm.subtitle)+" ")]})],2),_vm._t("default")],2),_c('el-aside',{staticClass:"link-card-aside"},[_vm._t("aside",function(){return [(_vm.icon)?_c('el-icon',{attrs:{"name":_vm.icon,"size":"32"}}):_vm._e()]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }