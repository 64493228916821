
import Vue from "vue";

import Collapse from "@/components/Collapse.vue";

export default Vue.extend({
  components: {
    Collapse,
  },
  data: () => ({}),
  props: {
    title: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  methods: {},
});
