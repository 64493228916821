
import Vue from "vue";
import AsideMain from "@/components/layouts/AsideMain.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    AsideMain,
    LinkCard,
  },
  data: () => ({
    passkeys: {
      data: new types.Counter(),
      loading: true,
    },
    sshkey: {
      data: new types.SSHKey(),
      loading: true,
    },
    token: {
      data: new types.AccessToken(),
      loading: true,
    },
    sessions: {
      data: new types.Counter(),
      loading: true,
    },
  }),
  computed: {},
  methods: {
    getPasskeysCount() {
      this.passkeys.loading = true;
      api
        .get("/api/v1/passkeys/count")
        .then((response) => {
          this.passkeys.data = new types.Counter(response.data);
        })
        .finally(() => {
          this.passkeys.loading = false;
        });
    },
    getSSHKey() {
      this.sshkey.loading = true;
      api
        .get("/api/v1/sshkey")
        .then((response) => {
          this.sshkey.data = new types.SSHKey(response.data);
        })
        .finally(() => {
          this.sshkey.loading = false;
        });
    },
    getPersonalAccessToken() {
      this.token.loading = true;
      api
        .get("/api/v1/token")
        .then((response) => {
          this.token.data = new types.AccessToken(response.data);
        })
        .finally(() => {
          this.token.loading = false;
        });
    },
    getSessionsCount() {
      this.sessions.loading = true;
      api
        .get("/api/v1/sessions/count")
        .then((response) => {
          this.sessions.data = new types.Counter(response.data);
        })
        .finally(() => {
          this.sessions.loading = false;
        });
    },
    load() {
      this.getPasskeysCount();
      this.getSSHKey();
      this.getPersonalAccessToken();
      this.getSessionsCount();
    },
  },
  mounted() {
    this.load();
  },
});
