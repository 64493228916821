
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import AccessRequests from "@/views/personal/managed-accounts/AccessRequests.vue";
import Expiration from "@/views/personal/external-accounts/Expiration.vue";
import AccessToken from "@/views/personal/service-accounts/AccessToken.vue";
import Applications from "@/views/personal/service-accounts/Applications.vue";
import Events from "@/views/personal/service-accounts/Events.vue";
import Password from "@/views/personal/service-accounts/Password.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Main,
    MultiCard,
    CardItem,
    Expiration,
    AccessRequests,
    AccessToken,
    Applications,
    Events,
    Password,
  },
  data: () => ({
    account: null as types.Account | null,
    loading: true,
  }),
  computed: {},
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/managed-accounts/${this.$route.params.id}`)
        .then((response) => {
          this.account = new types.Account(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
