
import Vue from "vue";
import Dialog from "@/components/layouts/Dialog.vue";

export default Vue.extend({
  components: {
    Dialog,
  },
  data: () => ({}),
  props: {
    visible: {
      type: Boolean,
    },
  },
  computed: {},
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
  },
});
