
import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({
    expanded: false,
  }),
  props: {
    title: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {},
});
