
import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({}),
  props: {
    contents: {
      type: Array,
    },
    embedded: {
      type: Boolean,
    },
  },
  computed: {
    origin(): string {
      return document.location.origin;
    },
  },
  methods: {},
});
