
import Vue from "vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import Dialog from "@/components/layouts/Dialog.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    MultiCard,
    CardItem,
    Dialog,
  },
  data: () => ({
    lastToken: undefined as types.AccessToken | undefined,
    visible: false,
    loading: false,
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  computed: {
    tokenNotExpiring(): boolean {
      const exp = new Date(this.account.lastAccessToken!.expiresAt);
      exp.setDate(exp.getDate() - 14);
      const now = new Date();
      return exp > now;
    },
  },
  methods: {
    createToken() {
      this.lastToken = this.account.lastAccessToken;
      this.loading = true;
      api
        .post(`/api/v1/service-accounts/${this.account.id}/token`, {})
        .then((response) => {
          this.account.lastAccessToken = new types.AccessToken(response.data);
          this.visible = true;
          this.$emit("update");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    revokeToken() {
      this.lastToken = undefined;
      this.loading = true;
      api
        .delete(`/api/v1/service-accounts/${this.account.id}/token`)
        .then(() => {
          this.account.lastAccessToken = undefined;
          this.$emit("update");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
