
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    Main,
    Dialog,
    MultiCard,
    CardItem,
  },
  data: () => ({
    requests: new types.PagedResponse(),
    loading: true,
    systems: new types.PagedResponse(),
    loadingSystems: false,
    request: {
      loading: false,
      visible: false,
      form: {
        system: new types.System(),
        role: new types.Role(),
        justification: "",
        error: "",
        rules: {
          system: [{ required: true }],
          role: [{ required: true }],
        },
      },
    },
  }),
  computed: {},
  methods: {
    load() {
      this.findAccessRequests();
    },
    findAccessRequests() {
      this.loading = true;
      api
        .get("/api/v1/accesses")
        .then((response) => {
          this.requests = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openRequestAccess() {
      this.request.visible = true;
      this.loadingSystems = true;
      api
        .get("/api/v1/systems")
        .then((response) => {
          this.systems = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.loadingSystems = false;
        });
    },
    requestAccess() {
      this.request.loading = true;
      api
        .post("/api/v1/accesses", {
          systemID: this.request.form.system.id,
          roleID: this.request.form.role.id,
          justification: this.request.form.justification,
        })
        .then((response) => {
          this.systems = new types.PagedResponse(response.data);
          this.request.visible = false;
          this.load();
        })
        .catch((error) => {
          this.request.form.error = util.error(error);
        })
        .finally(() => {
          this.request.loading = false;
        });
    },
    resetRequestForm() {
      this.request.form.system = new types.System();
      this.request.form.role = new types.Role();
      this.request.form.justification = "";
      this.request.form.error = "";
    },
  },
  mounted() {
    this.load();
  },
});
