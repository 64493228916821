
import Vue from "vue";

import util from "@/util";

export default Vue.extend({
  components: {},
  data: () => ({
    id: "",
  }),
  props: {
    tag: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {},
  mounted() {
    this.id = util.normalize(this.$el.textContent!);
  },
});
