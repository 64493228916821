
import Vue from "vue";
import Header from "@/components/layouts/Header.vue";
import Menu from "@/components/Menu.vue";
import Logo from "@/components/Logo.vue";
import Icon from "@/components/Icon.vue";

export default Vue.extend({
  components: {
    Header,
    Menu,
    Logo,
    Icon,
  },
  data: () => ({
    showMobileMenu: false,
  }),
  computed: {},
  methods: {
    back() {
      this.$router.push(this.$route.query.from || this.$route.meta!.back);
    },
  },
});
