
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    Main,
    Dialog,
    MultiCard,
    CardItem,
  },
  data: () => ({
    key: null as types.SSHKey | null,
    loading: true,
    upload: {
      form: {
        intAtt: "",
        att: "",
        error: "",
        rules: {
          intAtt: [{ required: true }],
          att: [{ required: true }],
        },
      },
      visible: false,
      loading: false,
    },
    remove: {
      visible: false,
      loading: false,
      form: {
        error: "",
        rules: {},
      },
      reasonOptions: [
        {
          label: "Unspecified",
          value: "Unspecified",
        },
        {
          label: "Device lost or stolen",
          value: "KeyCompromise",
        },
      ],
    },
  }),
  computed: {},
  methods: {
    load() {
      this.key = null;
      this.loading = true;
      api
        .get("/api/v1/sshkey")
        .then((response) => {
          this.key = new types.SSHKey(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    uploadKey() {
      this.upload.loading = true;
      this.upload.form.error = "";
      api
        .post("/api/v1/sshkey", {
          certificate: this.upload.form.intAtt,
          statement: this.upload.form.att,
        })
        .then((response) => {
          this.upload.visible = false;
          this.load();
        })
        .catch((error) => {
          this.upload.form.error = util.error(error);
        })
        .finally(() => {
          this.upload.loading = false;
        });
    },
    removeKey() {
      this.remove.loading = true;
      api
        .delete("/api/v1/sshkey")
        .then(() => {
          this.remove.visible = false;
          this.load();
        })
        .catch((error) => {
          this.remove.form.error = util.error(error);
        })
        .finally(() => {
          this.remove.loading = false;
        });
    },
    async readIntAtt(f: FileList) {
      this.upload.form.intAtt = await util.readFile(f[0]);
    },

    async readAtt(f: FileList) {
      this.upload.form.att = await util.readFile(f[0]);
    },
  },
  mounted() {
    this.load();
  },
});
