
import Vue from "vue";

import util from "@/util";

export default Vue.extend({
  components: {},
  data: () => ({}),
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {
    copyCode() {
      util.copy((this.$refs!.code! as HTMLElement).textContent!);
    },
  },
});
