
import Vue from "vue";
import LoginApp from "@/components/layouts/LoginApp.vue";

import api from "@/api";
import * as consts from "@/consts";
import util from "@/util";

export default Vue.extend({
  components: {
    LoginApp,
  },
  data: () => ({
    form: {
      otp: "",
      error: "",
      rules: {
        otp: [{ required: true }],
      },
    },
    loading: false,
    send: {
      loading: false,
    },
    notification: {
      expiresAt: null,
      visible: false,
    },
  }),
  props: {
    flow: String,
    oneTimePassword: String,
  },
  computed: {},
  methods: {
    getOneTimePassword() {
      this.form.error = "";
      this.send.loading = true;
      this.notification.visible = false;
      api
        .post("/api/v1/login/otp/email", {})
        .then((response) => {
          this.notification.expiresAt = response.data.expiresAt;
          this.notification.visible = true;
        })
        .catch((error) => {
          this.form.error = util.error(error);
        })
        .finally(() => {
          this.send.loading = false;
        });
    },
    loginOneTimePassword() {
      this.form.error = "";
      this.loading = true;
      api
        .post("/api/v1/login/otp/verify", {
          otp: this.oneTimePassword || this.form.otp.toUpperCase(),
        })
        .then(() => {
          this.next();
        })
        .catch((error) => {
          this.form.error = util.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    usePasskey() {
      this.$router.push({
        path: "/login/passkeys",
        query: this.$route.query,
      });
    },
    next() {
      const query = this.$route.query;
      delete query.code;
      this.$router.push({
        path: "/login/password",
        query: query,
      });
    },
    back() {
      this.$cookies.remove("email");
      this.$router.push({
        path: "/login",
        query: this.$route.query,
      });
    },
  },
  mounted() {
    if (this.oneTimePassword) {
      this.loginOneTimePassword();
    } else if (this.flow === consts.flows.ONBOARDING) {
      this.getOneTimePassword();
    }
  },
  destroyed() {
    this.notification.visible = false;
  },
});
