
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Card from "@/components/layouts/Card.vue";
import CardItem from "@/components/layouts/CardItem.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Main,
    Card,
    CardItem,
  },
  data: () => ({
    inventory: new types.PagedResponse(),
    loading: true,
  }),
  props: {},
  computed: {},
  methods: {
    load() {
      this.loading = true;
      api
        .get("/api/v1/inventory")
        .then((response) => {
          this.inventory = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
