
import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({}),
  props: {
    title: {
      type: String,
    },
    visible: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    width: {
      type: String,
      default: "small",
    },
    back: {
      type: Boolean,
    },
  },
  computed: {},
  methods: {
    open() {
      this.$emit("open");
    },
    close() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
  },
});
