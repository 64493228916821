
import Vue from "vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import Dialog from "@/components/layouts/Dialog.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    MultiCard,
    CardItem,
    Dialog,
  },
  data: () => ({
    passkeys: new types.PagedResponse(),
    loading: true,
    reset: {
      visible: false,
      loading: false,
      form: {
        ticketID: "",
        error: "",
        rules: {
          ticketID: [{ required: true }],
        },
      },
    },
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/accounts/${this.account.id}/passkeys`)
        .then((response) => {
          this.passkeys = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetPasskeys() {
      this.reset.loading = true;
      const opts = util.query({
        ticketID: this.reset.form.ticketID,
      });
      api
        .delete(`/api/v1/accounts/${this.account.id}/passkeys?${opts}`)
        .then(() => {
          this.load();
          this.$emit("update");
          this.reset.visible = false;
        })
        .catch((error) => {
          this.reset.form.error = util.error(error);
        })
        .finally(() => {
          this.reset.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
