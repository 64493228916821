
import Vue from "vue";
import Alert from "@/components/Alert.vue";

export default Vue.extend({
  components: {
    Alert,
  },
  data: () => ({}),
  props: {
    ok: {
      type: Boolean,
    },
    must: {
      type: Boolean,
    },
  },
  computed: {},
  methods: {
    alertTypeFromStatus(ok?: boolean, must?: boolean): string {
      if (ok === undefined) {
        return "error";
      }
      return ok ? "success" : must ? "error" : "warning";
    },
  },
});
