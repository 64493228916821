
import Vue from "vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import Dialog from "@/components/layouts/Dialog.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    MultiCard,
    CardItem,
    Dialog,
  },
  data: () => ({
    revoke: {
      visible: false,
      loading: false,
    },
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  computed: {},
  methods: {
    revokeToken() {
      this.revoke.loading = true;
      api
        .delete(`/api/v1/accounts/${this.account.id}/token`)
        .then(() => {
          this.account.lastAccessToken = undefined;
          this.revoke.visible = false;
          this.$emit("update");
        })
        .finally(() => {
          this.revoke.loading = false;
        });
    },
  },
});
