
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Main,
    CardItem,
    MultiCard,
  },
  data: () => ({
    loading: true,
    licenses: new types.PagedResponse(),
    request: {
      license: null as types.License | null,
      loading: false,
    },
  }),
  computed: {},
  methods: {
    load(loading = false) {
      this.loading = loading;
      return api
        .get("/api/v1/licenses")
        .then((response) => {
          this.licenses = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    requestLicense(license: types.License) {
      this.request.license = license;
      this.request.loading = true;
      api
        .put(`/api/v1/licenses/${license.id}/keys`, {})
        .then((response) => {
          return this.load();
        })
        .finally(() => {
          this.request.license = null;
          this.request.loading = false;
        });
    },
  },
  mounted() {
    this.load(true);
  },
});
