
import Vue from "vue";
import Icon from "@/components/Icon.vue";
import Logo from "@/components/Logo.vue";

export default Vue.extend({
  components: {
    Icon,
    Logo,
  },
  data: () => ({
    sticky: window.scrollY > 0,
  }),
  computed: {},
  methods: {
    handleScroll() {
      this.sticky = window.scrollY > 0;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
});
