
import Vue from "vue";
import LoginApp from "@/components/layouts/LoginApp.vue";

import api from "@/api";
import * as consts from "@/consts";
import store from "@/store";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    LoginApp,
  },
  data: () => ({
    loading: false,
    form: {
      email: store.getters.email(),
      error: "",
      rules: {
        email: [{ required: true }],
      },
    },
    notification: {
      title: "",
      message: "",
      visible: false,
    },
  }),
  props: {
    accountID: String,
    flow: String,
  },
  computed: {},
  methods: {
    login() {
      this.form.error = "";
      this.loading = true;
      this.notification.visible = false;
      api
        .post("/api/v1/login", {
          login: this.accountID || this.form.email,
        })
        .then((response) => {
          const r = new types.LoginResponse(response.data);
          let path = this.$route.path;
          const query = this.$route.query;
          delete query.accountID;
          if (r.isExpired) {
            this.notification.title = "Your account has expired";
            this.notification.message =
              "Please contact your manager for assistance.";
          } else if (r.hasPasskeys) {
            path = "/login/passkeys";
            if (r.isPasswordChangeRequired) {
              query.flow = consts.flows.PASSWORD;
              query.required = "1";
            } else if (r.isPasswordExpired) {
              query.flow = consts.flows.PASSWORD;
              query.expired = "1";
            } else if (query.flow === consts.flows.ENROLL) {
              path = "/login/otp";
            } else {
              query.flow = consts.flows.SIGN_IN;
            }
          } else {
            if (r.isPasswordChangeRequired) {
              path = "/password/update";
              query.from = "/login";
              query.required = "1";
            } else if (r.isPasswordExpired) {
              this.notification.title = "You must change your password";
              this.notification.message =
                "Please contact Helpdesk for assistance. " +
                "If you cannot do it yourself, you can ask your manager to help you.";
            } else {
              path = "/login/otp";
              query.flow = consts.flows.ONBOARDING;
            }
          }
          if (this.notification.title) {
            this.notification.visible = true;
          } else {
            this.$router.push({
              path: path,
              query: query,
              hash: this.$route.hash,
            });
          }
        })
        .catch((error) => {
          this.form.error = util.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.accountID || this.form.email) {
      this.login();
    }
  },
  destroyed() {
    this.notification.visible = false;
  },
});
