
import Vue from "vue";
import Header from "@/components/Header.vue";
import Loading from "@/components/layouts/Loading.vue";

export default Vue.extend({
  components: {
    Header,
    Loading,
  },
  data: () => ({}),
  props: {
    loading: {
      type: Boolean,
    },
  },
  computed: {},
  methods: {},
});
