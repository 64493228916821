
import Vue from "vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";
import Codebox from "@/components/Codebox.vue";
import Dialog from "@/components/layouts/Dialog.vue";

import * as types from "@/types";

export default Vue.extend({
  components: {
    MultiCard,
    CardItem,
    Codebox,
    Dialog,
  },
  data: () => ({
    raw: {
      event: null as types.Event | null,
      visible: false,
    },
  }),
  props: {
    events: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {},
  methods: {
    openRaw(event: types.Event) {
      this.raw.event = event;
      this.raw.visible = true;
    },
    closeRaw() {
      this.raw.event = null;
    },
  },
});
