
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Main,
    Dialog,
    MultiCard,
    CardItem,
  },
  data: () => ({
    sessions: new types.PagedResponse(),
    loading: true,
    terminateAll: {
      visible: false,
      loading: false,
    },
  }),
  computed: {},
  methods: {
    load(loading = false) {
      this.loading = loading;
      return api
        .get("/api/v1/sessions")
        .then((response) => {
          this.sessions = new types.PagedResponse(response.data);
          const thisIndex = this.sessions.items.findIndex(
            (s: types.Session) => s.id === this.$store.state.session.id
          );
          this.sessions.items.push(...this.sessions.items.splice(0, thisIndex));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    terminateAllSessions() {
      this.terminateAll.loading = true;
      api
        .delete("/api/v1/sessions")
        .then(() => {
          return this.load(true);
        })
        .finally(() => {
          this.terminateAll.visible = false;
          this.terminateAll.loading = false;
        });
    },
  },
  mounted() {
    this.load(true);
  },
});
