
import Vue from "vue";
import Fullscreen from "@/components/layouts/Fullscreen.vue";
import ContactHelpDesk from "@/components/ContactHelpDesk.vue";

export default Vue.extend({
  components: {
    Fullscreen,
    ContactHelpDesk,
  },
  data: () => ({
    visible: false,
  }),
  props: {
    applicationID: String,
  },
});
