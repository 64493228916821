
import Vue from "vue";
import LoginApp from "@/components/layouts/LoginApp.vue";
import Alert from "@/components/Alert.vue";

import api from "@/api";
import util from "@/util";
import store from "@/store";

export default Vue.extend({
  components: {
    LoginApp,
    Alert,
  },
  data() {
    return {
      loading: false,
      form: {
        email: store.getters.email(),
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        checkError: "",
        error: "",
        rules: {
          currentPassword: [{ required: true }],
          newPassword: [
            {
              validator: (this as any).validateNewPassword,
            },
          ],
          confirmNewPassword: [
            {
              validator: (this as any).validateConfirmNewPassword,
            },
          ],
        },
      },
    };
  },
  props: {
    from: String,
    expired: Boolean,
    required: Boolean,
  },
  computed: {},
  methods: {
    validateNewPassword(rule: any, value: string, callback: Function) {
      if (value === "") {
        callback(new Error(`${rule.field} is required`));
      } else if (value.length < 12) {
        callback(new Error(`${rule.field} must be at least 12 characters`));
      } else {
        api
          .post("/api/v1/password/check", {
            password: this.form.newPassword,
          })
          .then(() => {
            callback();
          })
          .catch((error) => {
            callback(new Error(util.error(error)));
          });
      }
    },
    validateConfirmNewPassword(rule: any, value: string, callback: Function) {
      if (value === "") {
        callback(new Error(`${rule.field} is required`));
      } else if (value !== this.form.newPassword) {
        callback(new Error("passwords don't match"));
      } else {
        callback();
      }
    },
    changePassword() {
      this.form.error = "";
      this.loading = true;
      api
        .put("/api/v1/password", {
          oldPassword: this.form.currentPassword,
          newPassword: this.form.newPassword,
        })
        .then((response) => {
          if (this.$store.state.account) {
            this.$store.state.account.attrs.pwdLastSet = new Date();
          }
          this.$notify.success({
            title: "Your password was changed",
            message: `Please note that it may take some time for your new password to synchronize across all systems.`,
            duration: 0,
          });
          this.next();
        })
        .catch((error) => {
          this.form.error = util.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    next() {
      this.$router.push(this.from || this.$route.meta?.back || "/");
    },
  },
});
