
import Vue from "vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import CardItem from "@/components/layouts/CardItem.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    MultiCard,
    Dialog,
    CardItem,
  },
  data: () => ({
    password: undefined as types.Password | undefined,
    visible: false,
    loading: false,
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  computed: {},
  methods: {
    resetPassword() {
      this.loading = true;
      api
        .put(`/api/v1/service-accounts/${this.account.id}/password`, {})
        .then((response) => {
          this.password = new types.Password(response.data);
          this.visible = true;
          this.$emit("update");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
