
import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({}),
  props: {
    loading: {
      type: Boolean,
    },
  },
  computed: {},
  methods: {},
});
