
import Vue from "vue";
import Collapse from "@/components/Collapse.vue";

export default Vue.extend({
  components: {
    Collapse,
  },
  data: () => ({}),
  computed: {},
  methods: {},
});
