import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";

import * as consts from "@/consts";
import * as types from "@/types";

Vue.use(Vuex);
Vue.use(VueCookies);

function session(): types.Session | null {
  const s = Vue.$cookies.get("state");
  return s ? new types.Session(s) : null;
}

const store = new Vuex.Store({
  state: {
    account: null as types.Account | null,
    session: session(),
    waiting: false,
    wait401: false,
    message: null as types.Notification | null,
  },
  getters: {
    email: (state, getters) => (): string => {
      return Vue.$cookies.get("email") || "";
    },
    // 
    // known authentication requirements
    // 
    hasNothing: (state, getters) => (): boolean => {
      return true;
    },
    hasSession: (state, getters) => (): boolean => {
      return state.session !== null;
    },
    hasOneTimePassword: (state, getters) => (): boolean => {
      return state.session?.firstFactor === consts.AUTH_FACTOR_ONE_TIME_PASSWORD;
    },
    hasPasskey: (state, getters) => (): boolean => {
      return state.session?.firstFactor === consts.AUTH_FACTOR_PASSKEY;
    },
    hasPassword: (state, getters) => (): boolean => {
      return state.session?.secondFactor === consts.AUTH_FACTOR_PASSWORD;
    },
    hasOneFactor: (state, getters) => (): boolean => {
      return getters.hasOneTimePassword() || getters.hasPasskey();
    },
    hasOneTimePasswordAndPassword: (state, getters) => (): boolean => {
      return getters.hasOneTimePassword() && getters.hasPassword();
    },
    hasPasskeyAndPassword: (state, getters) => (): boolean => {
      return getters.hasPasskey() && getters.hasPassword();
    },
    // 
    // known role requirements
    // 
    hasUserRole: (state, getters) => (): boolean => {
      return true;
    },
    hasAdminRole: (state, getters) => (): boolean => {
      return state.account?.props.isAdmin === true;
    },
    hasApplicationAdminRole: (state, getters) => (): boolean => {
      return state.account?.props.isApplicationAdmin === true;
    },
    hasLicensingAdminRole: (state, getters) => (): boolean => {
      return state.account?.props.isLicensingAdmin === true;
    },
    hasTrainingAdminRole: (state, getters) => (): boolean => {
      return state.account?.props.isTrainingAdmin === true;
    },
    hasAdminOrApplicationAdminRole: (state, getters) => (): boolean => {
      return getters.hasAdminRole() || getters.hasApplicationAdminRole();
    },
    hasAdminOrLicensingAdminRole: (state, getters) => (): boolean => {
      return getters.hasAdminRole() || getters.hasLicensingAdminRole();
    },
    hasAdminOrTrainingAdminRole: (state, getters) => (): boolean => {
      return getters.hasAdminRole() || getters.hasTrainingAdminRole();
    },
    hasAnyAdminRole: (state, getters) => (): boolean => {
      return (
        getters.hasAdminRole() ||
        getters.hasApplicationAdminRole() ||
        getters.hasLicensingAdminRole() ||
        getters.hasTrainingAdminRole()
      );
    },
  },
  mutations: {
    updateAccount(state, account: types.Account) {
      state.account = account;
      state.wait401 = false;
    },
    deleteAccount(state, cleanup = true) {
      state.account = null;
      state.wait401 = true;
      if (cleanup) {
        Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie));
      } else {
        Vue.$cookies.remove("state");
      }
    },
    updateSession(state) {
      state.session = session();
    },
  },
  actions: {
    update({ commit }, account: types.Account) {
      commit("updateAccount", account);
      commit("updateSession");
    },
    reload({ commit }) {
      commit("updateSession");
    },
    logout({ commit }, cleanup = true) {
      commit("deleteAccount", cleanup);
      commit("updateSession");
    },
  },
});

export default store;
