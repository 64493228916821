
import Vue from "vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    MultiCard,
    CardItem,
  },
  data: () => ({
    sessions: new types.PagedResponse(),
    loading: true,
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  computed: {},
  methods: {
    load() {
      this.loading = true;
      return api
        .get(`/api/v1/accounts/${this.account.id}/sessions`)
        .then((response) => {
          this.sessions = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    terminateAllSessions() {
      this.loading = true;
      api
        .delete(`/api/v1/accounts/${this.account.id}/sessions`)
        .then(() => {
          this.load();
          this.$emit("update");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
