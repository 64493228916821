
import Vue from "vue";
import Card from "@/components/layouts/Card.vue";
import CardItem from "@/components/layouts/CardItem.vue";

export default Vue.extend({
  components: {
    Card,
    CardItem,
  },
  data: () => ({}),
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    noDataMessage: {
      type: String,
    },
  },
  computed: {},
  methods: {},
});
