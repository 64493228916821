
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Dialog from "@/components/layouts/Dialog.vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import CardItem from "@/components/layouts/CardItem.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Main,
    Dialog,
    MultiCard,
    CardItem,
  },
  data: () => ({
    token: null as types.AccessToken | null,
    loading: true,
    visible: false,
  }),
  computed: {},
  methods: {
    load() {
      this.token = null;
      this.loading = true;
      api
        .get("/api/v1/token")
        .then((response) => {
          this.token = new types.AccessToken(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createToken() {
      this.token = null;
      this.loading = true;
      api
        .post("/api/v1/token", {})
        .then((response) => {
          this.token = new types.AccessToken(response.data);
          this.visible = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    revokeToken() {
      this.loading = true;
      api
        .delete("/api/v1/sessions")
        .then(() => {
          this.token = null;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
