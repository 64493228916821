
import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({}),
  props: {
    title: {
      type: String,
      default: "Passport",
    },
  },
  computed: {},
  methods: {},
});
