
import Vue from "vue";
import LoginApp from "@/components/layouts/LoginApp.vue";

import api from "@/api";
import * as consts from "@/consts";
import * as types from "@/types";
import util from "@/util";
import store from "@/store";

export default Vue.extend({
  components: {
    LoginApp,
  },
  data: () => ({
    form: {
      email: store.getters.email(),
      password: "",
      error: "",
      rules: {
        password: [{ required: true }],
      },
    },
    loading: false,
  }),
  props: {
    flow: String,
    from: String,
  },
  computed: {},
  methods: {
    loginPassword() {
      this.form.error = "";
      this.loading = true;
      api
        .post("/api/v1/login/password/verify", { password: this.form.password })
        .then((response) => {
          this.$store.dispatch("update", new types.Account(response.data));
        })
        .then(() => {
          this.next();
        })
        .catch((error) => {
          this.form.error = util.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    next() {
      if (
        this.flow === consts.flows.ONBOARDING ||
        this.flow === consts.flows.ENROLL
      )
        this.$router.push({
          path: "/passkeys/create",
          query: this.$route.query,
          hash: this.$route.hash,
        });
      else {
        this.$router.push({
          path: "/login/checkup",
          query: this.$route.query,
          hash: this.$route.hash,
        });
      }
    },
    back() {
      this.$cookies.remove("email");
      this.$router.push({
        path: "/login",
        query: this.$route.query,
        hash: this.$route.hash,
      });
    },
  },
  mounted() {
    if (
      this.$store.state.session?.secondFactor === consts.AUTH_FACTOR_PASSWORD
    ) {
      this.next();
    }
  },
});
