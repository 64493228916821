
import Vue from "vue";
import TableCard from "@/components/layouts/TableCard.vue";

import api from "@/api";
import * as types from "@/types";
import util from "@/util";

export default Vue.extend({
  components: {
    TableCard,
  },
  data: () => ({
    courses: new types.PagedResponse(),
    filtered: new Array<types.CourseProgress>(),
    loading: true,
    columns: [
      {
        prop: "course.id",
        label: "ID",
      },
      {
        prop: "course.displayName",
        label: "Name",
      },
      {
        prop: "score",
        label: "Score",
      },
      {
        prop: "progress",
        label: "Progress",
      },
      {
        prop: "passed",
        label: "Passed",
        format: (row: any, column: any, passed: boolean) =>
          passed ? "✔" : "✘",
      },
      {
        prop: "finishedAt",
        label: "Finished",
        format: (row: any, column: any, finished: string) =>
          finished ? util.toDatetime(finished) : "N/A",
      },
    ],
    alwaysColumns: ["course.displayName"],
    hiddenColumns: ["course.id", "score", "progress", "finishedAt"],
    actions: [
      { label: "Copy ID", command: "copy", icon: "i-copy-o--16" },
      { label: "View", command: "view", icon: "i-external-link-o--16" },
    ],
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/accounts/${this.account.id}/courses`)
        .then((response) => {
          this.courses = new types.PagedResponse(response.data);
          this.courses.items = this.courses.items || [];
          this.filtered = this.courses.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search(query: string) {
      this.filtered = this.courses.items.filter((c: types.CourseProgress) => {
        return c.course.displayName.toLowerCase().includes(query);
      });
    },
    copyID(row: any) {
      util.copy(row.course.id);
    },
    view(row: any) {
      util.openTab(`/admin/courses/${row.course.id}`);
    },
  },
  mounted() {
    this.load();
  },
});
