import axios, { AxiosError, AxiosResponse } from "axios";

import router, { getFlow } from "@/router";
import store from "@/store";
import util from "@/util";

const api = axios.create({
  baseURL: document.location.origin,
  headers: {
    common: {
      "X-Passport-Api": "1",
      "Content-Type": "application/json",
    },
  },
  withCredentials: true,
});
api.interceptors.response.use(
  function (response: AxiosResponse) {
    store.dispatch("reload");
    return response;
  },
  function (error: AxiosError) {
    if (!error.response) {
      store.state.message = {
        title: error.message,
        message: "Please check your connection",
        type: "error",
      };
    } else {
      const r = error.response as AxiosResponse;
      switch (r.status) {
        case 401:
          if (!store.state.wait401) {
            store.dispatch("logout", false);
            if (r.config.url?.includes("/login")) {
              router.push({
                path: "/login",
                query: router.currentRoute.query,
                hash: router.currentRoute.hash,
              });
            } else {
              router.push({
                path: "/login",
                query: {
                  flow: getFlow(router.currentRoute),
                  from: router.currentRoute.fullPath,
                },
              });
            }
          }
          break;
        case 404:
          break;
        default:
          store.state.message = {
            title: r.data.code || r.statusText,
            message: util.titleCase(r.data.message) || "An unexpected error has occurred, please try again later",
            type: "error",
            duration: 0,
          };
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default api;
