
import Vue from "vue";
import LoginApp from "@/components/layouts/LoginApp.vue";

import api from "@/api";
import * as consts from "@/consts";
import util from "@/util";

export default Vue.extend({
  components: {
    LoginApp,
  },
  data: () => ({
    form: {
      error: "",
      rules: {},
    },
    loading: false,
    disabled: false,
    notification: {
      visible: false,
    },
  }),
  props: {
    flow: String,
  },
  computed: {},
  methods: {
    loginPasskeys() {
      this.form.error = "";
      this.loading = true;
      this.notification.visible = false;
      api
        .post("/api/v1/login/passkeys", {})
        .then((response) => {
          const pk = response.data.publicKey;
          pk.challenge = util.b64d(pk.challenge);
          pk.allowCredentials.forEach(function (cred: any) {
            cred.id = util.b64d(cred.id);
          });
          return navigator.credentials.get({ publicKey: pk });
        })
        .then((cred: any) => {
          return api.post("/api/v1/login/passkeys/verify", {
            id: cred.id,
            rawId: util.b64(cred.rawId),
            type: cred.type,
            response: {
              authenticatorData: util.b64(cred.response.authenticatorData),
              clientDataJSON: util.b64(cred.response.clientDataJSON),
              signature: util.b64(cred.response.signature),
              userHandle: util.b64(cred.response.userHandle),
            },
          });
        })
        .then(() => {
          this.next();
        })
        .catch((error) => {
          this.form.error = util.error(error);
          if (!error.response) {
            this.notification.visible = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enroll() {
      this.notification.visible = false;
      const query = this.$route.query;
      query.flow = consts.flows.ENROLL;
      this.$router.push({
        path: "/login/otp",
        query: query,
        hash: this.$route.hash,
      });
    },
    next() {
      if (this.flow === consts.flows.PASSWORD) {
        this.$router.push({
          path: "/password/update",
          query: this.$route.query,
          hash: this.$route.hash,
        });
      } else {
        const query = this.$route.query;
        query.flow = consts.flows.SIGN_IN;
        this.$router.push({
          path: "/login/password",
          query: query,
          hash: this.$route.hash,
        });
      }
    },
    back() {
      this.$cookies.remove("email");
      this.$router.push({
        path: "/login",
        query: this.$route.query,
        hash: this.$route.hash,
      });
    },
  },
  mounted() {
    if (this.$store.state.session?.firstFactor === consts.AUTH_FACTOR_PASSKEY) {
      this.next();
    } else if (!util.browserSupportsWebAuthn()) {
      this.form.error = "This browser doesn't support passkeys";
      this.disabled = true;
    } else if (document.hasFocus()) {
      this.loginPasskeys();
    }
  },
  destroyed() {
    this.notification.visible = false;
  },
});
