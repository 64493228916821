
import Vue from "vue";
import MultiCard from "@/components/layouts/MultiCard.vue";
import LinkCard from "@/components/layouts/LinkCard.vue";
import Search from "@/components/Search.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    MultiCard,
    LinkCard,
    Search,
  },
  data: () => ({
    applications: new types.PagedResponse(),
    loading: true,
    query: "",
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  computed: {
    origin(): string {
      return document.location.origin;
    },
    filtered(): types.Application[] {
      return this.filterApplications(this.applications.items).sort(
        (a: types.Application, b: types.Application) => {
          return a.displayName.localeCompare(b.displayName);
        }
      );
    },
  },
  methods: {
    load() {
      this.findApplications();
    },
    findApplications() {
      this.loading = true;
      api
        .get(`/api/v1/service-accounts/${this.account.id}/applications`)
        .then((response) => {
          this.applications = new types.PagedResponse(response.data);
          this.applications.items.map((value: any, index: number) => {
            this.applications.items[index] = new types.Application(value);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterApplications(applications: types.Application[]): types.Application[] {
      const query = this.query.trim().toLowerCase();
      if (query === "") {
        return applications;
      }
      return applications.filter((s: types.Application) => {
        return (
          s.displayName.toLowerCase().includes(query) ||
          s.url.toLowerCase().replace(".acronis.work", "").includes(query)
        );
      });
    },
  },
  mounted() {
    this.load();
  },
});
