var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    collapse: true,
    expanded: _vm.expanded,
    disabled: _vm.disabled,
  }},[_c('div',{staticClass:"collapse-header",on:{"click":function($event){if (!_vm.disabled) {
        _vm.expanded = !_vm.expanded;
      }}}},[_c('el-container',[_c('el-main',{staticClass:"collapse-title"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2),(!_vm.disabled)?_c('el-aside',{staticClass:"collapse-aside-right"},[_c('el-button',{attrs:{"type":"ghost","icon":_vm.expanded ? 'i-chevron-down--16' : 'i-chevron-right--16'}})],1):_vm._e()],1)],1),(_vm.expanded)?[_c('el-divider'),_c('div',{staticClass:"collapse-main"},[_vm._t("default")],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }