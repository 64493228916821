import Vue from "vue";
import { mapGetters } from "vuex";
import App from "@/App.vue";
import UIKit from "@acronis/ui-kit";
import "@acronis/ui-kit/lib/ui-kit.min.css";
import VueApexCharts from "vue-apexcharts"

import * as consts from "@/consts";
import router from "@/router";
import store from "@/store";
import util from "@/util";

Vue.use(UIKit);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.config.productionTip = false;

Vue.mixin({
  data: () => ({
    flows: consts.flows,
    links: {
      helpdeskWeb: "https://helpdesk.acronis.work",
      helpdesk: "hd@acronis.com",
      security: "security@acronis.com",
    },
  }),
  computed: {
    // authentication and authorization
    ...mapGetters([
      "hasNothing",
      "hasSession",
      "hasOneTimePassword",
      "hasPasskey",
      "hasPassword",
      "hasOneFactor",
      "hasOneTimePasswordAndPassword",
      "hasPasskeyAndPassword",
      "hasUserRole",
      "hasAdminRole",
      "hasApplicationAdminRole",
      "hasLicensingAdminRole",
      "hasTrainingAdminRole",
      "hasAnyAdminRole",
    ]),
    // OS & browsers
    isMac: (): boolean => util.userAgent.os.name === "Mac OS",
    isWindows: (): boolean => util.userAgent.os.name === "Windows",
    isIOS: (): boolean => util.userAgent.os.name === "iOS",
    isAndroid: (): boolean => util.userAgent.os.name === "Android",
    isChrome: (): boolean => util.userAgent.browser.name === "Chrome",
    isFirefox: (): boolean => util.userAgent.browser.name === "Firefox",
  },
  methods: {
    colorFromSeverity: util.colorFromSeverity,
    copy: util.copy,
    flag: util.flag,
    normalize: util.normalize,
    openTab: util.openTab,
    toDate: util.toDate,
    toDatetime: util.toDatetime,
    toRelativeDate: util.toRelativeDate,
    userAgentString: util.userAgentString,
    locationString: util.locationString,
    shortURL: util.shortURL,
    stringify: util.stringify,
    titleCase: util.titleCase,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
