
import Vue from "vue";
import Events from "@/components/Events.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Events,
  },
  data: () => ({
    events: new types.PagedResponse(),
    loading: true,
  }),
  props: {
    account: {
      type: types.Account,
      required: true,
    },
  },
  computed: {},
  methods: {
    load() {
      this.loading = true;
      api
        .get(`/api/v1/service-accounts/${this.account.id}/events`)
        .then((response) => {
          this.events = new types.PagedResponse(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
});
