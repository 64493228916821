
import Vue from "vue";
import Main from "@/components/layouts/Main.vue";
import Header from "@/components/layouts/Header.vue";
import Codebox from "@/components/Codebox.vue";
import ContactHelpDesk from "@/components/ContactHelpDesk.vue";

import api from "@/api";
import * as types from "@/types";

export default Vue.extend({
  components: {
    Main,
    Header,
    Codebox,
    ContactHelpDesk,
  },
  data: () => ({
    loading: true,
    visible: false,
    debug: new types.DebugInfo(),
  }),
  computed: {},
  methods: {
    getDebugInfo() {
      this.loading = true;
      api
        .get("/api/v1/debug", {})
        .then((response) => {
          this.debug = new types.DebugInfo(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getDebugInfo();
  },
});
